.topbox{
    display:flex;
    flex-direction: column;
    width:100%;
    min-height: 100vh;
height: 100%;
    background: var(--pallate-blue-gray-800, #1E293B);    
}

.backnname{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.paycontainer {
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 8px;
    background: var(--pallate-blue-gray-800, #1E293B);    
}

.button-container1 {
    display: inline-flex;
    padding: 8px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 20px;
    background: var(--pallate-blue-gray-500, #64748B);
    border: none;
    margin-top: 32px;
}

.back-button1 {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 5.269px 4.25px 5.25px 4.25px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
}
.back-button1:hover{
    cursor: pointer;
}
.arrow1 {
    width: 15.5px;
    height: 13.481px;
    flex-shrink: 0;
}

.left-line{
    width: 1px;
    min-height: 934px;
    align-self: stretch;
    background: #475569;
}

.top-line{
    margin-top: 32px;
    margin-bottom: 32px;
    width:100%;
    height: 1px;
    background: #475569;
}

.left-pane{
    display: flex;
    padding: 21px 0px 0px 32px;
    flex-direction: column;
    flex-shrink: 0;
}

.setting{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.set-details{
    display:flex;
    width: 289px;
    margin-top: 52px;
    flex-direction: column;
    align-items: flex-start;
}

.usage-details{
    display: flex;
    padding: 8px 12px;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.billing-details{
    display: flex;
    padding: 8px 12px;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    background: rgba(34, 211, 238, 0.20);
}

.pay-main{
    display: flex;
    width:100%;
    max-width: 1100px;
    min-height: 891px;
    height: 100%;
   
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.pay-color{
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
    border-radius: 8px;
    background: rgba(15, 23, 42, 0.50);
}

.pay-infomain{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.pay-infohead{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.pay-line{
    width:100%;
    max-width: 1052px;
    height: 1px;
    background: #334155;
}

.pay-plan-details{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 14px;
}

.plan-head{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}
.plan-head-1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

}

.credits-left{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.price-main{
    display: flex;
    padding:24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
    border-radius: 8px;
    background: rgba(15, 23, 42, 0.50);
}

.price-details{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
}

.free-price{
    display: flex;
    width: 100%;
    max-width: 338px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.freemium{
    display: flex;
    width: 100%;
    max-width: 120px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px 8px 0px 0px;
    background: var(--pallate-indigo-500, #6366F1);
}

.free-details{
    display: flex;
    flex: 1 0 0;
    gap: 24px;
    max-width: 338px;
    min-height: 416px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    background: var(--pallate-blue-gray-900, #0F172A);
    position: relative;
}

.free-detailsin{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.free-header{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.freewithplan{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.free-body{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px; 
}

.free-credits{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.currentplan{
    position: absolute;
    display: flex;
    width:90px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    right:23px;
    flex-shrink: 0;
    top: 28px;
    border-radius: 16px;
    background: var(--pallate-teal-400, #2DD4BF);
}

.subscribe-button{
    display: flex;
    width: 100%;
    max-width: 120px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    opacity: 0.6;
    background: var(--pallate-blue-gray-300, #CBD5E1);
    box-shadow: 0px 0px 20px 0px rgba(209, 213, 219, 0.70);
    border: none;
}

.pre-price{
    display: flex;
    width: 100%;
    max-width: 338px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.premium{
    display: flex;
    width: 100%;
    max-width: 120px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px 8px 0px 0px;
    background: var(--pallate-pink-500, #EC4899);
}

.pre-details{
    display: flex;
    flex: 1 0 0;
    padding: 24px;
    max-width: 338px;
    min-height: 416px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    background: var(--pallate-blue-gray-900, #0F172A);
    position: relative;
}

/* .free-details{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
} */

.pre-header{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.prewithplan{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.pre-body{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px; 
}

.pre-credits{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

/* .currentplan{
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    right: 23px;
    top: 28px;
    border-radius: 16px;
    background: var(--pallate-teal-400, #2DD4BF);
} */

.subscribenow-button{
    display: flex;
    width: 100%;
    max-width: 150px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    border-radius: 4px;
    background: var(--pallate-pink-500, #EC4899);
    box-shadow: 0px 0px 20px 0px rgba(244, 114, 182, 0.70);
    border: none;
}

.custom-price{
    display: flex;
    width: 100%;
    max-width: 338px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.custom-plan{
    display: flex;
    width: 100%;
    max-width: 120px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px 8px 0px 0px;
    background: var(--pallate-sky-400, #38BDF8);
}

.custom-details{
    display: flex;
    padding: 24px;
    flex: 1 0 0;
    max-width: 338px;
    min-height: 416px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    background: var(--pallate-blue-gray-900, #0F172A);
}

.custom-header{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.cust-head-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.contactus-button{
    display: flex;
    width: 100%;
    max-width: 150px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    border-radius: 4px;
    background: var(--pallate-sky-400, #38BDF8);
    box-shadow: 0px 0px 20px 0px rgba(56, 189, 248, 0.70);
    border: none;
}

.paytemp{
    display: flex;
    width: 100%;
    max-width:340px;
    min-height: 800px;
    height: 100%;
    padding: 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 1;
    background: rgba(15, 23, 42, 0.50);
}

.pay-in-main{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.paychoice{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.paychoicehead{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.pay-in-line{
    width: 100%;
    height: 1px;
    background: #334155;
}

.card-details{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}



.successtemp{
    display: flex;
    width: 100%;
    max-width: 315px;
    min-height: 800px;
    height: 100%;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex-shrink: 1;
    background: rgba(15, 23, 42, 0.50);
    position: relative;
}

.successmain{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.successmsg{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.congmsg{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.successline{
    width: 100%;
    max-width:310px;
    height: 1px;
    background: #334155;
}

.earnedmsg{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.earncredit{
    display: flex;
    flex-direction: column;
}

.close-icon {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 16px;
    top: 16px;
}

.close-icon:hover {
    cursor: pointer;
}

.usagemain{
    display: flex;
    width: 100%;
    max-width:1471px;
    min-height: 866px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex-shrink: 0;
}

.usagecolor{
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
    background: rgba(15, 23, 42, 0.50);
}

.usageinform{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.usagehead{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch; 
}

.usageline{
    width: 100%;
    max-width:1423px;
    height: 1px;
    background: #334155;
}

.stayinformed{
    display: flex;
    flex-direction: column;
}

.creditsviewmain{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
}

.prodview{
    display: flex;
    align-items: flex-end;
    gap: 40px;
    width: 100%;
}

.prodselect{
    display: flex;
    width: 100%;
    max-width: 417px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 4px;
}

.prodbody{
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--light-forms-border, #CBD5E1);
    background: var(--light-forms-background, #FFF);
}

.prodselbody{
    display: flex;
    width:100%;
    color:var(--light-text-secondary-text, #64748B);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    align-items: center;
    gap: 60px;
    align-self: stretch;
    border-radius: 4px;
    border: None;
    outline: None;
    background: var(--light-forms-background, #FFF);
}

.seltext{
    flex: 1 0 0;
    color: var(--light-text-secondary-text, #64748B);

    /* Text / base / normal-400 */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.dropping{
    display: flex;
    width: 24px;
    height: 24px;
    padding: 0px 6.372px 0px 6.339px;
    justify-content: center;
    align-items: center;
}

.viewselect{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    gap: 40px;
    border-radius: 4px;
}
.viewbody{
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 8px;
    width: 100%;
    max-width: 250px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--light-forms-border, #CBD5E1);
    background: var(--light-forms-background, #FFF);
}

.viewselbody{
    flex: 1 0 0;
    color: var(--light-text-secondary-text, #64748B);
    background: None;
    /* Text / base / normal-400 */
    border:None;
    outline:None;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.calenderbody{
    display: flex;
    width: 100%;
    max-width:218px;
    margin-top: 30px;
    height: 48px;
    align-items: center;
}

.calinput{
    display: flex;
    margin-left: 40px;
    padding: 12px;
    height:24px;
    align-items: center;
    border:None;
    outline:None;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    color: var(--light-text-secondary-text, #64748B);
    gap: 8px;
    flex: 1 0 0;
    border-radius: 4px 0px 0px 4px;
    border-top: 1px solid var(--light-forms-border, #CBD5E1);
    border-bottom: 1px solid var(--light-forms-border, #CBD5E1);
    border-left: 1px solid var(--light-forms-border, #CBD5E1);
    background: var(--light-forms-background, #FFF);
}

.calinput-yearly{
    display: flex;
    margin-left: 40px;
    padding: 12px;
    height:24px;
    align-items: center;
    border:None;
    outline:None;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    height:48px; 
    color: var(--light-text-secondary-text, #64748B);
    gap: 8px;
    flex: 1 0 0;
    border-radius: 4px 0px 0px 4px;
    border-top: 1px solid var(--light-forms-border, #CBD5E1);
    border-bottom: 1px solid var(--light-forms-border, #CBD5E1);
    border-left: 1px solid var(--light-forms-border, #CBD5E1);
    background: var(--light-forms-background, #FFF);
}

.calendaricon{
    display: flex;
    width: 24px;
    height: 24px;
    padding: 2.25px 4.25px 3.25px 4.25px;
    justify-content: center;
    align-items: center;
    background-image: url('../assets/icons/celandar.svg');
    border-radius: 0px 4px 4px 0px;
    /*background: var(--pallate-sky-400, #38BDF8);*/
}

.creditsbar{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.usagebar{
    display: flex;
    width: 100%;
    max-width: 1109px;
    height: 100px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex-shrink: 0;
}

.progressbar{
    display: flex;
    width: 100%;
    max-width: 1109px;
    height: 20px;
    padding-right: 0px;
    align-items: center;
    flex-shrink: 1;
    background: var(--pallate-blue-gray-700, #334155);
}

.contactmain{
    display: flex;
    width: 100%;
    max-width: 340px;
    height: 800px;
    padding: 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 1;
    background: rgba(15, 23, 42, 0.50);
}

.contactdetails{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.contacthead{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.contacttop{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.contactline{
    width: 100%;
    max-width:310px;
    height: 1px;
    background: #334155;
}

.contactbody{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.detailsbody{
    display: flex;
    height: 402px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.detailsbodymain{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height:402px;
    overflow: auto;
    gap: 16px;
    align-self: stretch;
}

.detailsbodymain::-webkit-scrollbar{
    display: none;
}

.namedet{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
}

.message{
    display: flex;
    height: 128px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.contactfooter{
    display: flex;
    flex-direction: row;
    margin-top: 32px;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
}

.contsuccesstemp{
    display: flex;
    width: 100%;
    max-width: 340px;
    height: 800px;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex-shrink: 1;
    background: rgba(15, 23, 42, 0.50);
    position: relative;
}

.contsuccessmain{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.contactsucessbody{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.thankmsg{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    align-self: stretch;
}

.contact-close-icon {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 16px;
    top: 16px;
}

.contact-close-icon:hover {
    cursor: pointer;
}

.bottomvalues{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-left: 22px;
}
.pay-right{
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 56px;
    gap: 20px;
    
}

.enter-message{
    display: flex;
    padding: 12px;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--light-forms-border, #CBD5E1);
    background: var(--light-forms-background, #FFF);
}



@media screen and (min-width:600px) and (max-width:980px){
    .price-details{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        flex-wrap: wrap;
    }
    .prodview{
        display: flex;
        align-items: flex-end;
        gap: 40px;
        flex-wrap: wrap;
    }
    .viewselect{
        display: flex;
        width: 100%;
        max-width:250px;
        flex-direction: row;
        align-items: flex-start;
        gap: 40px;
        border-radius: 4px;
        flex-wrap: wrap;
    }
    .set-details{
        display:flex;
        max-width: 289px;
        width: 100%;
        margin-top: 52px;
        flex-direction: column;
        align-items: flex-start;
    }

}
@media screen and (max-width:1300px){
    .pay-right{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        padding-left: 56px;
        gap: 20px;
    }
}

@media screen and (max-width: 700px){


    .topbox{
        width: 100%;
    }


    .paycontainer{
        width:100%;
        justify-content: center;
        /* margin-left: 24px;
        margin-right: 24px; */
        padding: 0px;
        align-items: center;
    }

    .price-details{
        display: flex;
        flex-direction: column;
        gap:24px;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
    }
    .pay-plan-details{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 14px;
        width: 100%;
    }
    
    .plan-head{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        width: 50%;
    }
    .plan-head-1{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        width: 50%;
    
    }

    .pay-right{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-left: 0px;
        /* padding-left: 24px;
        padding-right: 24px; */
        gap: 20px;
        align-items: center;
        
    }
    
    .pay-main{
        width:317px;
    }

    .paytemp{
        display: flex;
        align-items: center;
        width: 100%;
        height: 100vh;
        /* padding-left: 24px;
        padding-right: 24px; */
        padding:0;
        flex-shrink: 0;
        max-width: none;
        background: var(--pallate-blue-gray-700, #334155);
    }

    .pay-in-main{
        padding: 24px;
        height: 100vh;
        overflow-y: auto;
    }

    .paychoicehead{
        padding: 24px 0px 0px 0px;
    }

    .card-details{
        padding: 24px;
    }

    .contactdetails{
        padding: 34px;
        height: 100vh;
        overflow-y: auto;
    }

    .usagemain{
        width:317px;
        align-items: center;
    }

    .prodview{
        display: flex;
        flex-direction:column ;
        align-items: flex-end;
        gap: 40px;
        width: 100%;
    }

    .viewselect{
        flex-direction: column;
    }

    .prodbody{
        width: 150px;
    }

    .calinput{
        margin-left: 0px;
    }

    .calinput-yearly{
        margin-left: 0px;
        height:50px;
    }

    .contactmain{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        /* padding-left: 24px;
        padding-right: 24px; */
        padding:0;
        flex-shrink: 0;
        max-width: none;
        background: var(--pallate-blue-gray-700, #334155);
        height: 100vh;
    }

    .contsuccesstemp{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        /* padding-left: 24px;
        padding-right: 24px; */
        padding:0;
        flex-shrink: 0;
        max-width: none;
        background: var(--pallate-blue-gray-700, #334155);
        height: 100vh;
    }

    .contacsuccessmain{
        padding: 20px;
    }




}